.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.legend {
  list-style: none;
  padding: 0;
  margin: 0;
}

.legend li {
  line-height: 36px;
  vertical-align: middle;
  margin: 10px 0;
}

.legend label {
  margin: 0 10px;
  font-weight: bold;
  font-size: 14px;
  color: #32325d;
}

.table-label-value {
  width: 100%;
}

.table-label-value td {
  padding: 3px 5px;
}

.card-container .row div {
  padding-top: 5px;
  padding-bottom: 5px;
}

.val {
  font-weight: bold;
}

.gopay-qr-code {
  width: 300px
}

.gopay {
  text-align: center;
}

.loader {
  position: absolute;
  z-index: 1;
  background-color: rgba(75, 75, 78, 0.2);
}

.qa-item {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 14pt !important;
  color: #333;
}

.answer-item {
  margin-bottom: 5px !important;
}

.form-control {
  color: #121212;
}

.text-muted {
  color: #555555 !important;
}

.nav-question button {
  margin: 5px 5px !important;
}

.text-bold {
  font-weight: bold;
}

.text-white {
  color: #ffffff;
}

.err-profile {
  width: 100%;
}

.err-profile span {
  text-align: center;
}
.err-profile {
  width: 100%;
}

.err-profile span {
  text-align: center;
}

.question-container {
  height: 300px;
  overflow: auto;
}

.group-card {
  padding-bottom: 20px;
}

.align-right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.status {
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 9pt;
  font-weight: bold;
}

.status-success {
  background-color: #a5eecc;
  color: #198754;
  border: 1px solid #198754;
}

.status-failed {
  background-color: #f5ccd2;
  color: #e41529;
  border: 1px solid #e41529;
}

.btn-tryout {
  font-size: 10pt;
  padding: 10px 5px;
}

.btn-tryout-nav {
  padding: 5px;
}

.btn-tryout-nav img {
  height: 30px;
}

.color-white {
  color: #ffffff;
}

.result-choice {
  font-weight: bold;
}

.result-major {
}

.result-status {
  font-weight: bold;
  float: right;
}

.data-pk {
  color: #aeaeae;
}

.pagination li {
  /* padding: 5px 12px !important; */
  border-radius: 50% !important;
  margin-right: 4px;
}

.previous {
  border-radius: 4px;
}

.pagination li a {
  border: 1px solid #5e72e4;
  border-radius: 50% !important;
  padding: 5px 12px !important;
}

.pagination li a:hover {
  border: 1px solid #5e72e4;
  border-radius: 50% !important;
}

.pagination > .selected {
  z-index: 1;
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}
