@font-face {
  font-family: 'Nunito sans';
  src: url('./fonts/Nunito_Sans/NunitoSans-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Nunito sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-datepicker-wrapper {
  width: 100%;
}

a:hover {
  text-decoration: none;
}

.swiper-button-next,
.swiper-button-prev {
  height: 40px;
  width: 40px;
  background: #ffffff;
  border-radius: 100px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 24px;
}
.swiper-button-disabled {
  opacity: 0 !important;
}

b, strong {
	font-weight: bold;
}